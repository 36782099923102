// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        textAlign: 'center',
        bgcolor: 'background.paper',
        borderTop: `1px solid ${theme.palette.border.main}`,
        width: '100%',
        position: 'relative',
        bottom: 0,
        mt: 'auto',
        // Remove padding and margin inherited from parent components
        px: 0,
      }}
    >
      <Typography variant="body2" sx={{ mx: 0 }}>
        &copy; {new Date().getFullYear()} Copper Leaf Farm. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
