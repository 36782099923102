// src/components/About.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function About() {
  return (
    <Box id="about" sx={{ py: 5, pt: 10 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          About Us
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          Welcome to Copper Leaf Farm, a premier horse training and boarding facility dedicated to
          nurturing the bond between horse and rider. Our mission is to provide exceptional care,
          training, and support for both horses and their owners, ensuring a thriving equestrian
          community.
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          At CLF, we offer comprehensive training programs tailored to meet the needs of each horse
          and rider combination. Our experienced trainer utilizes proven methods to enhance skills,
          build confidence, and prepare for shows and competitions. Whether you are a novice
          rider or an experienced competitor, we provide the resources and expertise to help you
          achieve your goals.
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          Our facility features top-notch amenities, including spacious stalls, ample turnout
          areas, and state-of-the-art indoor and outdoor arenas for training. We take pride in maintaining a safe and
          supportive environment where horses can thrive and riders can flourish. We also regularly
          participate in shows and competitions, allowing our riders to showcase their skills and
          accomplishments.
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          Beyond training and competition, we foster a sense of community among our boarders and
          riders. At Copper Leaf Farm, we believe in building lasting friendships through shared
          experiences and a passion for equestrian sports. Join us for events, clinics, and fun
          gatherings that celebrate our love for horses and riding!
        </Typography>
      </Container>
    </Box>
  );
}

export default About;
