import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Import your single background image
import backgroundImage from '../assets/smile4k.png'; // Adjust the path accordingly

function Splash({ isMobileView }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      id="splash"
      sx={{
        position: 'relative',
        aspectRatio: '16 / 9', // Replace with your image's aspect ratio (width / height)
        overflow: 'hidden',
        color: (theme) => theme.palette.text.primary,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', // Ensures the background covers the entire Box
        backgroundPosition: 'center', // Centers the background image
        backgroundRepeat: 'no-repeat',
        margin: '-25px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 1, // Ensure content is above the overlay
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          px: 2,
        }}
      >
      </Box>

      <Box
        sx={{
          position: 'relative',
          zIndex: 1, // Ensure content is above the overlay
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 2,
        }}
      >
        <ArrowDownwardIcon
          sx={{
            fontSize: '2vw',
            animation: 'bounce 1.5s infinite',
            cursor: 'pointer',
            color: 'white', // Ensure the arrow color contrasts with the background
          }}
          onClick={() =>
            window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
          }
        />
      </Box>

      <style jsx>{`
        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      `}</style>
    </Box>
  );
}

export default Splash;
