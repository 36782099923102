// src/components/Contact.js
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  Alert, // Imported Alert component
} from '@mui/material';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Reference to the ReCAPTCHA component
  const recaptchaRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = true;
      }
    });
    setErrors(newErrors);
    const isCaptchaValid = !!captchaValue;
    setCaptchaError(!isCaptchaValid);
    return Object.keys(newErrors).length === 0 && isCaptchaValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setSubmissionStatus(null);

    if (!validateForm()) {
      setDisabled(false);
      return;
    }

    try {
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        subject: formData.subject,
        message: formData.message,
        timestamp: new Date().toLocaleString(),
        current_year: new Date().getFullYear(),
        'g-recaptcha-response': captchaValue,
      };

      // Send email via EmailJS
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      console.log('Email successfully sent!', result.status, result.text);

      // Reset form after successful submission
      setFormData({ name: '', email: '', subject: '', message: '' });
      setErrors({});
      setCaptchaValue(null);
      setCaptchaError(false);

      // Reset the ReCAPTCHA widget
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      // Set submission status to success
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error sending email:', error);
      // Set submission status to error
      setSubmissionStatus('error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Box id="contact" sx={{ py: 5 }}>
      <Container maxWidth="md">
        <Paper
          elevation={0}
          sx={{
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            align="left"
            sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="body1"
            paragraph
            align="left"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Feel free to reach out using the form below. Whether you have a question or just want to
            connect, We'll get back to you as soon as possible!
          </Typography>

          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {/* Form Fields */}
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={formData.name}
                  onChange={handleChange}
                  disabled={disabled}
                  error={!!errors.name}
                  helperText={errors.name ? 'Name is required.' : ''}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={disabled}
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email ? 'Email is required.' : ''}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Subject"
                  name="subject"
                  variant="outlined"
                  value={formData.subject}
                  onChange={handleChange}
                  disabled={disabled}
                  error={!!errors.subject}
                  helperText={errors.subject ? 'Subject is required.' : ''}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Message"
                  name="message"
                  variant="outlined"
                  value={formData.message}
                  onChange={handleChange}
                  disabled={disabled}
                  multiline
                  rows={5}
                  error={!!errors.message}
                  helperText={errors.message ? 'Message is required.' : ''}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Include the reCAPTCHA */}
                <Box
                  sx={{
                    width: '100%',
                    transform: 'scale(0.90)',
                    transformOrigin: '0 0',
                    '@media (max-width:600px)': {
                      transform: 'scale(0.85)',
                    },
                    '@media (max-width:400px)': {
                      transform: 'scale(0.80)',
                    },
                  }}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => {
                      setCaptchaValue(value);
                      setCaptchaError(false);
                    }}
                  />
                </Box>
                {captchaError && (
                  <Typography color="error" variant="body2">
                    CAPTCHA is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disabled}
                      size="medium"
                      sx={{
                        borderRadius: 2,
                        '&:hover': { backgroundColor: (theme) => theme.palette.primary.main },
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                  {submissionStatus && (
                    <Grid item xs={12} sm>
                      <Alert
                        severity={submissionStatus === 'success' ? 'success' : 'error'}
                        onClose={() => setSubmissionStatus(null)}
                        sx={{ mt: { xs: 2, sm: 0 } }}
                      >
                        {submissionStatus === 'success'
                          ? 'Your message has been sent successfully!'
                          : 'An error occurred while sending your message. Please try again.'}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
}

export default Contact;
