import React from 'react';
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from '@mui/material';
import {
  Email as EmailIcon,
  Facebook as FacebookIcon,
  Work as BriefcaseIcon,
  School as SchoolIcon,
  LocationOn as LocationIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import { Link as ScrollLink } from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import profile from '../assets/profile.jpg';

const drawerWidth = 320; // Adjusted width to accommodate content

function Sidebar({ open, handleSidebarToggle, isDesktop }) {
  const theme = useTheme();

  const navItems = [
    { text: 'About', to: 'about' },
    { text: 'Services', to: 'services' },
    { text: 'Competitions', to: 'competitions' },
    { text: 'Contact', to: 'contact' },
  ];

  const socialLinks = [
    {
      icon: <EmailIcon sx={{ color: theme.palette.customColors.icon }} />, // Set icon color
      label: 'Email',
      href: 'mailto:dnadams11@comcast.net',
    },
    {
      icon: <FacebookIcon sx={{ color: theme.palette.customColors.icon }} />, // Set icon color
      label: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=100063611616757',
    },
  ];

  const drawerContent = (
    <Box
      sx={{
        width: drawerWidth,
        p: 3,
        pb: 10,
        boxSizing: 'border-box',
        height: '100%', // Set to 100% to span the entire height of the drawer
        overflowY: 'auto', // Allow vertical scrolling
        maxHeight: '100vh', // Limit height to viewport height
        backgroundColor: theme.palette.background.paper, // Ensure background matches theme
        color: theme.palette.text.primary, // Text color to match theme
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in WebKit-based browsers
        },
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
      }}
    >
      {/* Profile Section */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Avatar
          src={profile}
          alt="Profile Picture"
          sx={{ width: 150, height: 150, mx: 'auto', mb: 2 }}
        />
        <Typography variant="h6" color={theme.palette.text.primary}>
          Nicole Adams
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          (She/Her)
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Equestrian Trainer
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <BriefcaseIcon fontSize="small" sx={{ mr: 1, color: theme.palette.customColors.icon }} />
            <Typography variant="body2">Copper Leaf Farm</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <SchoolIcon fontSize="small" sx={{ mr: 1, color: theme.palette.customColors.icon }} />
            <Typography variant="body2">
              Ohio Wesleyan University
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <LocationIcon fontSize="small" sx={{ mr: 1, color: theme.palette.customColors.icon }} />
            <Typography variant="body2">Laporte, Indiana</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: theme.palette.text.secondary,
            }}
          >
            <LinkIcon fontSize="small" sx={{ mr: 1, color: theme.palette.customColors.icon }} />
            <a
              href="https://www.copperleaf.farm/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography
                variant="body2"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                copperleaf.farm
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>

      {/* Social Links */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        {socialLinks.map((link, index) => (
          <Tooltip key={index} title={link.label} arrow>
            <IconButton
              component="a"
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              sx={{ mx: 1 }}
            >
              {React.cloneElement(link.icon, { sx: { color: theme.palette.customColors.icon } })} {/* Set icon color */}
            </IconButton>
          </Tooltip>
        ))}
      </Box>

      <Divider sx={{ mb: 4 }} />

      {/* Navigation Links */}
      <List>
        {navItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={ScrollLink}
            to={item.to}
            spy={true}
            smooth={true}
            offset={-64} // Adjust based on Header height
            duration={500}
            sx={{
              '& .MuiListItemText-primary': {
                color: theme.palette.text.primary,
              },
              '&:hover .MuiListItemText-primary': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      variant={isDesktop ? 'persistent' : 'temporary'}
      open={open}
      onClose={handleSidebarToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        BackdropProps: {
          invisible: isDesktop ? false : true, // Dim the background only for temporary drawers
        },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          top: '64px', // Position below the header
          backgroundColor: theme.palette.background.paper, // Ensure background matches theme
          color: theme.palette.text.primary, // Ensure text color matches theme
          ...(isDesktop && {
            position: 'fixed',
          }),
        },
      }}
      anchor="left"
    >
      {drawerContent}
    </Drawer>
  );
}

export default Sidebar;
