// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Box, IconButton, Typography, Tooltip } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import {
  LightMode as WbSunnyIcon,
  DarkMode as DarkModeIcon,
  Menu as MenuIcon,
  PhoneAndroid as PhoneAndroidIcon,
  Monitor as DesktopWindowsIcon,
} from '@mui/icons-material';
import { Link } from 'react-scroll';

function Header({
  darkMode,
  toggleDarkMode,
  handleSidebarToggle,
  isDesktop,
  isMobileView,
  toggleMobileView,
}) {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: '100%',
        borderBottom: `1px solid ${theme.palette.border.main}`,
        backdropFilter: 'blur(5px)',
        bgcolor: alpha(theme.palette.background.paper, 0.8),
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          minHeight: '64px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
        }}
      >
        {/* Left Section: Menu Button */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Open Menu">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon sx={{ color: theme.palette.customColors.icon }} />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Center Section: Name */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <Link to="splash" smooth={true} duration={500} style={{ textDecoration: 'none' }}>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.text.primary,
                textAlign: 'center',
                fontFamily: 'Birthstone, sans-serif',
              }}
            >
              Copper Leaf Farm
            </Typography>
          </Link>
        </Box>

        {/* Right Section: Toggle Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Mobile/Desktop View Toggle Button - Visible Only on Desktop */}
          {isDesktop && (
            <Tooltip title={isMobileView ? 'Current View: Mobile' : 'Current View: Desktop'}>
              <IconButton
                onClick={toggleMobileView}
                color="inherit"
                aria-label="toggle mobile/desktop view"
                sx={{ mr: 1 }}
              >
                {isMobileView ? (
                  <PhoneAndroidIcon sx={{ color: theme.palette.customColors.icon }} />
                ) : (
                  <DesktopWindowsIcon sx={{ color: theme.palette.customColors.icon }} />
                )}
              </IconButton>
            </Tooltip>
          )}

          {/* Dark/Light Mode Toggle */}
          <Tooltip title={darkMode ? 'Dark Mode' : 'Light Mode'}>
            <IconButton
              onClick={toggleDarkMode}
              color="inherit"
              aria-label="toggle dark/light mode"
            >
              {darkMode ? (
                <DarkModeIcon sx={{ color: theme.palette.customColors.icon }} />
              ) : (
                <WbSunnyIcon sx={{ color: theme.palette.customColors.icon }} />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
