import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Grid,
  GlobalStyles, // Import GlobalStyles
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom Arrow Components for Slider
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)',
        zIndex: 1,
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.7)',
        },
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
      </svg>
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        left: 10,
        transform: 'translateY(-50%)',
        zIndex: 1,
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.7)',
        },
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.41 7.41L10.83 12l4.58 4.59L14 18l-6-6 6-6z" />
      </svg>
    </IconButton>
  );
};

// Helper function to dynamically import all images from a folder
const importAllImages = (requireContext) => {
  return requireContext.keys().map(requireContext);
};

// Define all carousel image contexts at the top
const carouselImagesMap = {
  carousel1: importAllImages(
    require.context('../assets/carousel1/images', false, /\.(png|jpe?g|svg)$/)
  ),
  carousel2: importAllImages(
    require.context('../assets/carousel2/images', false, /\.(png|jpe?g|svg)$/)
  ),
  // Add more carousels as needed
};

// Slider Settings with Custom Dots Positioning
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  appendDots: (dots) => (
    <Box
      sx={{
        position: 'absolute',
        bottom: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
      }}
    >
      <ul style={{ margin: 0, padding: 0 }}>{dots}</ul>
    </Box>
  ),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

function Competitions() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentTitle, setCurrentTitle] = useState('');

  const handleViewImages = (images, title) => {
    setCurrentImages(images);
    setCurrentTitle(title);
    setOpen(true);
  };

  const handleDownload = (zipLink) => {
    window.open(zipLink, '_blank');
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImages([]);
    setCurrentTitle('');
  };

  // Competitions Data
  const competitions = [
    {
      id: 1,
      title: 'National Horse Training Championship 2023',
      description:
        'Showcased our advanced horse training techniques and training programs at the National Championship.',
      carouselFolder: 'carousel1',
      zipLink: '/assets/carousel1/competition1.zip',
      placements: [
        { position: '1st Place', category: 'Advanced Dressage' },
        { position: '2nd Place', category: 'Show Jumping' },
      ],
      date: '2023-06-20',
      placementDescription:
        'Copper Leaf Farm secured 1st place in Advanced Dressage and 2nd place in Show Jumping at the National Horse Training Championship 2023.',
    },
    {
      id: 2,
      title: 'Regional Horse Trials 2023',
      description:
        'Participated in the Regional Horse Trials, demonstrating our commitment to excellence in horse training and competition.',
      carouselFolder: 'carousel2',
      zipLink: '/assets/carousel2/competition2.zip',
      placements: [
        { position: 'Best Trainer Award', category: 'Equestrian Training' },
        { position: '3rd Place', category: 'Mounted Games' },
      ],
      date: '2023-09-15',
      placementDescription:
        'Our trainers received the Best Trainer Award, and our team achieved 3rd place in Mounted Games at the Regional Horse Trials 2023.',
    },
    // Add more competitions as needed
  ];

  return (
    <Box
      id="competitions"
      sx={{
        py: 10,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* GlobalStyles to override slick dots color */}
      <GlobalStyles
        styles={{
          /* Inactive Dots */
          '.slick-dots li button:before': {
            color: 'white', // Inactive dot color
            opacity: 1, // Ensure dots are fully opaque
            fontSize: '12px', // Optional: Adjust dot size
          },
          /* Active Dot */
          '.slick-dots li.slick-active button:before': {
            color: 'gray', // Active dot color
            opacity: 1,
          },
          /* Optional: Remove default outline on focus */
          '.slick-dots li button:focus:before': {
            outline: 'none',
          },
        }}
      />

      <Container maxWidth="md">
        <Typography
          variant="h4"
          gutterBottom
          align="left"
          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
          Competitions
        </Typography>
        {competitions.map((competition) => {
          const images = carouselImagesMap[competition.carouselFolder] || [];

          return (
            <Card
              key={competition.id}
              sx={{
                mb: 5,
                boxShadow: 3,
                borderRadius: 2,
                transition: 'transform 0.3s, box-shadow 0.3s',
                backgroundColor: theme.palette.cardBackground,
                color: theme.palette.text.primary,
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                },
              }}
            >
              {images.length > 0 && (
                <Box sx={{ position: 'relative', height: 300 }}>
                  <Slider {...sliderSettings}>
                    {images.map((img, imgIndex) => (
                      <Box key={imgIndex} sx={{ position: 'relative' }}>
                        <img
                          src={img}
                          alt={`${competition.title} image ${imgIndex + 1}`}
                          style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover',
                          }}
                          loading="lazy"
                        />
                      </Box>
                    ))}
                  </Slider>
                </Box>
              )}
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                >
                  {competition.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mt: 1, color: theme.palette.text.primary }}
                >
                  {competition.description}
                </Typography>
                {/* Event Placements */}
                {competition.placements && competition.placements.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                    >
                      Event Placements
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {competition.placements.map((placement, pIndex) => (
                      <Typography key={pIndex} variant="body1" sx={{ mb: 1 }}>
                        <strong>{placement.position}:</strong> {placement.category}
                      </Typography>
                    ))}
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {competition.placementDescription}
                    </Typography>
                  </Box>
                )}
              </CardContent>
              <CardActions>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Button
                    size="small"
                    color="info"
                    onClick={() => handleViewImages(images, competition.title)}
                    sx={{ mr: 1, fontWeight: 'bold' }}
                  >
                    View Images
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => handleDownload(competition.zipLink)}
                    sx={{ mr: 1, fontWeight: 'bold' }}
                    startIcon={<DownloadIcon />}
                  >
                    Download Images
                  </Button>
                  {competition.link && (
                    <Button
                      size="small"
                      color="primary"
                      href={competition.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontWeight: 'bold' }}
                    >
                      More Info
                    </Button>
                  )}
                </Box>
              </CardActions>
            </Card>
          );
        })}
      </Container>

      {/* Modal for Viewing Image Collage */}
      <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth>
        <DialogTitle>
          {currentTitle}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {currentImages.map((img, imgIndex) => (
              <Grid item xs={12} sm={6} md={4} key={imgIndex}>
                <Box
                  component="img"
                  src={img}
                  alt={`${currentTitle} image ${imgIndex + 1}`}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    objectFit: 'cover',
                  }}
                  loading="lazy"
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Competitions;
