// src/App.js
import React, { useState, useMemo, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Box, useMediaQuery } from '@mui/material';
import getTheme from './theme';
import GlobalStylesComponent from './GlobalStyles';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Splash from './components/Splash';
import About from './components/About';
import Services from './components/Services';
import Competitions from './components/Competitions';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const theme = useMemo(() => getTheme(darkMode ? 'dark' : 'light'), [darkMode]);

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isDesktop) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [isDesktop]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const toggleMobileView = () => {
    setIsMobileView((prev) => !prev);
  };

  const drawerWidth = 320; // Width of the Sidebar

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStylesComponent />
      <Header
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        handleSidebarToggle={handleSidebarToggle}
        isDesktop={isDesktop}
        isMobileView={isMobileView}
        toggleMobileView={toggleMobileView}
      />

      {/* Outer Container */}
      <Box
        sx={{
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        {/* Transition Wrapper */}
        <Box
          sx={{
            width: isMobileView ? '375px' : '100%',
            maxWidth: '100%',
            margin: '0 auto',
            boxShadow: isMobileView ? theme.shadows[5] : 'none',
            transition: theme.transitions.create(['width', 'box-shadow'], {
              easing: theme.transitions.easing.easeInOut,
              duration: 500,
            }),
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            position: 'relative',
          }}
        >
          {/* Sidebar */}
          <Sidebar
            open={isSidebarOpen}
            handleSidebarToggle={handleSidebarToggle}
            isDesktop={isDesktop}
            isMobileView={isMobileView}
          />

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: 'background.default',
              color: 'text.primary',
              p: 3,
              mt: '64px', // Height of the Header
              // Adjust margin-left: no shift when in Mobile View
              ml: isDesktop && isSidebarOpen && !isMobileView ? `${drawerWidth}px` : 0,
              transition: theme.transitions.create(['padding'], {
                easing: theme.transitions.easing.easeInOut,
                duration: 500,
              }),
              // If in Mobile View, ensure full width within the constrained container
              ...(isMobileView && {
                width: '100%',
                maxWidth: '375px',
              }),
            }}
          >
            <Splash 
              isDesktop={isDesktop}
              isMobileView={isMobileView}/>
            <About />
            <Services />
            <Competitions />
            <Contact />
          </Box>

          {/* Footer */}
          {!isMobileView && <Footer />}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
