import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Grid,
  Avatar,
  Button,
} from '@mui/material';
import {
  Lightbulb as TrainingIcon,
  Home as BoardingIcon,
  Build as ConsultationIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { scroller } from 'react-scroll';

// Optional: Import high-quality images for each service
import TrainingImage from '../assets/services/training.jpg';
import BoardingImage from '../assets/services/boarding.jpg';
import ConsultationImage from '../assets/services/consultation.jpg';

// Styled Components for Enhanced Visuals
const GradientOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(to bottom right, rgba(0,0,0,0.3), rgba(0,0,0,0.7))',
  opacity: 0,
  transition: 'opacity 0.3s',
  '&:hover': {
    opacity: 1,
  },
}));

const StyledCard = styled(Card)(({ theme, backgroundImage }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[5],
  transition: 'transform 0.3s, box-shadow 0.3s',
  minHeight: 300,
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[10],
  },
}));

function Services() {
  const theme = useTheme();

  const services = [
    {
      title: 'Training',
      description:
        'Professional horse riding lessons tailored for all skill levels. Our experienced trainers will help you improve your riding techniques and build confidence.',
      icon: <TrainingIcon fontSize="large" />,
      image: TrainingImage,
      color: theme.palette.primary.main,
    },
    {
      title: 'Boarding',
      description:
        'Safe and comfortable boarding facilities for your horses. We provide top-notch care, spacious stalls, and a nurturing environment.',
      icon: <BoardingIcon fontSize="large" />,
      image: BoardingImage,
      color: theme.palette.secondary.main,
    },
    {
      title: 'Consultation',
      description:
        'Expert consultation services for horse care, training programs, and facility management. Let us help you optimize your equestrian endeavors.',
      icon: <ConsultationIcon fontSize="large" />,
      image: ConsultationImage,
      color: theme.palette.success.main,
    },
  ];

  const handleScrollToContact = () => {
    scroller.scrollTo('contact', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <Box
      id="services"
      sx={{
        py: 5,
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
          Our Services
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 6 }}
        >
          Discover the range of services we offer to cater to all your equestrian needs.
        </Typography>
        <Grid container spacing={6}>
          {services.map((service, index) => (
            <Grid item xs={12} md={6} key={index}>
              <StyledCard
                backgroundImage={service.image}
              >
                {service.image && <GradientOverlay />}
                <CardContent
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    color: service.image ? '#fff' : theme.palette.text.primary,
                    background: service.image ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                    transition: 'background 0.3s',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    p: 2,
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Avatar
                        sx={{
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          width: 40,
                          height: 40,
                          mr: 2,
                          color: service.image ? '#fff' : theme.palette.text.primary,
                        }}
                      >
                        {service.icon}
                      </Avatar>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {service.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      {service.description}
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    startIcon={<ArrowForwardIcon />}
                    onClick={handleScrollToContact}
                    sx={{
                      borderColor: '#fff',
                      color: '#fff',
                      '&:hover': {
                        borderColor: '#fff',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                      alignSelf: 'flex-start',
                      mt: 2,
                    }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Services;
